.select-css {
    display: inline;
    font-size: 12px;
    color: #444;
    line-height: 1;
    padding: 0.6em 2em 0.5em 0.8em;
    min-width: 10%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("../images/filter-drop-icon.svg");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.8em auto, 100%;

    border: 1px solid #8491b4;
    border-radius: 30px;
}
.select-css::-ms-expand {
    display: none;
}
.select-css:hover {
    border-color: #888;
}
.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}
.select-css option {
    font-weight: normal;
}
