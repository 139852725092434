.business-detail-col {
    display: flex;
    align-items: center;
}

/*@media (min-width: 769px) {*/
/*    .business-detail-col {*/
/*        width: 1px;*/
/*    }*/
/*}*/

.biz-detail {
    height: 77vh;
    overflow: auto;
    flex-grow: 1;
    margin: 1rem;
}

@media (max-width: 768px) {
    .biz-detail {
        height: auto;
        margin: 1rem 1.5rem;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.biz-detail::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.biz-detail {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.business-detail-top {
    display: flex;
}

.business-detail-top .left {
    flex: 12;
}

.business-detail-top .right {
    flex: 1;
    text-align: right;
}

.biz-detail-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}

.business-detail-props {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2rem;
    font-size: 0.9rem;
}

.biz-detail-name {
    font-size: 1.5rem;
    width: 70%;
}

.biz-detail-distance {
    text-align: right;
    vertical-align: center;
}

.biz-detail-rating {
    font-size: 0.8rem;
}

.biz-detail-cancel {
    text-align: center;
    color: gray;
    font-size: 1.2rem;
}

.biz-detail-address,
.biz-detail-phone {
    font-family: Arial, serif;
    font-size: 0.9rem;
}

.fa-address-card,
.fa-phone {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    color: grey;
}

.biz-detail-features {
    font-family: Arial, serif;
}

.biz-detail-links {
    margin-top: 0.7rem;
}

.biz-detail-link {
    background: #222455 0 0 no-repeat padding-box;
    border: 1px solid #232255;
    border-radius: 30px;
    opacity: 1;
    padding: 0.4rem 1.5rem;
    font-size: 0.8rem;
    color: #ffffff;
    font-family: Arial, serif;
    margin-right: 0.3rem;
}

.biz-detail-link:hover {
    color: lightblue;
}

.biz-detail-deals {
    font-family: Arial, serif;
    letter-spacing: 0;
}

.biz-detail-deals .deals-details {
    margin-top: 1.5rem;
    width: 95%;
}

.biz-detail-deals .deals-details.first {
    margin-top: 0;
}

.biz-detail-deals .deals-details .left {
    flex: 1;
}

.biz-detail-deals .deals-details .right {
    flex: 19;
}

.biz-detail-deals .deals-details .time-slots {
    font-size: 0.8rem;
}

.biz-detail-deals .deals-details .time-slot .day {
    flex: 1;
    padding-right: 1rem;
}

.biz-detail-deals .deals-details .time-slot .time {
    flex: 7;
    padding-left: 0;
}

.biz-detail-deals .deal-descriptions {
    margin-top: 0.6rem;
    font-size: 0.9rem;
}

.biz-detail-deals .deal-description {
    display: flex;
    line-height: 1.5rem;
}

.biz-detail-deals .deal-description .price-after {
    flex: 1;
    padding-right: 1rem;
}

.biz-detail-deals .deal-description .description {
    flex: 7;
}

.biz-detail-deals .price-before {
    margin-left: 1rem;
    text-decoration: line-through;
    font-size: 0.8rem;
    color: rosybrown;
}

@media (max-width: 768px) {
    .biz-detail-deals .deals-details .left {
        flex: 1;
    }

    .biz-detail-deals .deals-details .right {
        flex: 24;
    }

    .biz-detail-deals .deals-details .time-slot .day {
        flex: 1;
    }

    .biz-detail-deals .deals-details .time-slot .time {
        flex: 5;
    }

    .biz-detail-deals .deal-description .price-after {
        flex: 1;
        padding-right: 1rem;
    }

    .biz-detail-deals .deal-description .description {
        flex: 5;
    }
}

.biz-description {
    margin-top: 2rem;
    font-size: 0.8rem;
}

.external-link {
    margin-top: 2rem;
    font-size: 0.8rem;
}

.external-link .external-link-text {
    margin-left: 0.2rem;
    text-decoration: underline;
    margin-right: 1rem;
}

.img-carousel {
    margin-top: 1rem;
}

.carousel-img {
    height: 8rem;
}

@media (max-width: 768px) {
    .carousel-img {
        height: 7rem;
    }
}

.carousel-img {
    padding: 0.5rem;
    border-radius: 1.2rem;
}

.no-deals-oops {
    padding: 1rem;
}

@media (max-width: 768px) {
    .no-deals-oops {
        padding: 4rem 2rem;
    }
}
