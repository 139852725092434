.footer {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .footer {
        flex-direction: row;
    }
}

.footer {
    margin: 0 !important;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    flex: 0 0 auto;
    align-items: center;
}

.social-icons a {
    margin-right: 10px;
    font-size: 1rem;
}

.footer a:hover {
    color: #ef5800;
}

.footer-top {
    display: flex;
    flex: 2;
    align-items: center;
}

.footer-bottom {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
}

.footer-about {
    flex: 1;
}

.footer-mission-heading {
    font-weight: bold;
}

.footer-links {
    flex: 1;
}

.footer-social {
    flex: 1;
}
