.hidden {
    display: none;
}

.dealiem-dark-orange {
    color: #ef5800;
}

.center-text {
    text-align: center;
}

.underline-text {
    text-decoration: underline;
}
