.search-box {
    display: inline;
    font-size: 12px;
    color: #444;
    line-height: 1;
    padding: 0.45em 2.5em 0.45em 0.7em;
    min-width: 18%;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border: 1px solid #8491b4;
    border-radius: 30px;
    opacity: 1;

    background: #ffffff url("../images/search-icon.svg") no-repeat right 0.8rem
        top 50%;
    background-size: 1em auto, 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reset-button {
    display: inline;
    cursor: pointer;
    font-size: 0.8rem;
}

.reset-button .clear-text {
    text-decoration: underline;
}

.neighborhood-filter {
    min-width: 15%;
    background-image: url("../images/neighborhood-filter-icon.svg");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.8em auto, 100%;
}

@media (max-width: 768px) {
    .search-box {
        min-width: 50%;
    }

    .neighborhood-filter {
        min-width: 40%;
    }
}
