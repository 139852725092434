.tabbed-deals-container {
    margin-top: 2rem;
}

.tabbed-deals-tab {
    padding: 0.4rem 1.2rem 0.2rem 1.2rem;
    font-size: 0.9rem;
    background: none;
    border: none;
    line-height: 1.2;
    border-bottom-style: solid;
    border-bottom-color: lightgray;
    border-bottom-width: 0.1rem;
}

@media (max-width: 768px) {
    .tabbed-deals-tab {
        padding: 0.4rem 0.8rem 0.2rem 0.8rem;
    }
}

.tabbed-deals-tab.disabled {
    pointer-events: none;
    color: lightgray;
}

.tabbed-deals-panel {
    border: none;
    padding: 1rem 0 0 0;
    font-size: 0.85rem;
    animation: flash 0.3s;
}

@keyframes flash {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.RRT__inkbar {
    height: 3px;
    background-color: #ff8b33;
}

.RRT__tabs {
    flex-wrap: nowrap;
}
