.map-col {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: center;
}

.map {
    width: 100%;
    height: 77vh;
}

@media (max-width: 768px) {
    .map {
        height: 25vh;
    }
}

.pin {
    fill: #f15900;
    stroke-width: 0.2rem;
    stroke: #fff;
}

.clicked {
    stroke-width: 0.3rem;
    stroke: #b7c4e8;
}

.popup {
    font-size: 0.8rem;
}

.popup-name {
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.popup-address {
    margin-top: 0.2rem;
}

.popup-more-deals {
    color: #ef5800;
    text-decoration: underline;
}

.no-deals-oops-img {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
