.deals-col {
    display: flex;
    align-items: center;
}

.business-list {
    height: 77vh;
    overflow: auto;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .business-list {
        height: auto;
    }
}

/*Hide scrollbar for Chrome, Safari and Opera*/
/*.business-list::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

.business-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #f9f9f9;
}

.business-list::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #f9f9f9;
}

.business-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: lightgrey;
    cursor: grabbing;
}

/* Hide scrollbar for IE, Edge and Firefox */
.business-list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.empty-business {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
}

.empty-business > * {
    margin-bottom: 2rem;
}

.empty-business .send-deal-btn {
    background: #ef5800;
    color: white;
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.no-deals-img {
    width: 40%;
}

.deal-item {
    background: white;
    margin: 0 1rem 1rem 1rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0 0 1px rgba(10, 10, 10, 0.02);
    border-radius: 6px;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-flow: column nowrap;
    padding: 0.5rem 1rem;
}

.deal-top {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.biz-name {
    align-self: center;
    font-size: 1.1rem;

    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deal-ver-middle {
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.business-props {
    font-size: 0.8rem;
    font-weight: normal;
}

.business-props .rating-count {
    color: lightslategrey;
}

.price-indicator {
    color: lightslategrey;
    font-weight: normal !important;
}

.deal-bottom {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-flow: row nowrap;
    padding-top: 0.8rem;
}

.deal-left {
    flex: 2;
}

.deal-thumbnail {
    border-radius: 0.5rem;
}

.deal-middle {
    flex: 6;
    padding: 0 0 0 0.7rem;
}

.deals-details {
    width: 95%;
    display: flex;
    margin-top: 0.7rem;
}

.deals-details.first {
    margin-top: 0;
}

.deals-details .left {
    flex: 1;
}

.timing-icon {
    color: lightslategrey;
}

.deals-details .right {
    flex: 11;
}

@media (max-width: 768px) {
    .deals-details .right {
        margin-left: 0.5rem;
    }
}

.deals-details .time-slots {
    font-size: 0.7rem;
    color: lightslategrey;
}

.deals-details .time-slot {
    display: flex;
}

.deals-details .time-slot .day {
    flex: 1;
}

.deals-details .time-slot .time {
    flex: 3;
    padding-left: 0.5rem;
}

.deals-details .deal-descriptions {
    margin-top: 0.5rem;
    font-size: 0.85rem;
}

.deal-description {
    text-transform: capitalize;
    display: flex;
}

.deal-item .deal-description .price-after {
    flex: 1;
}

.deal-item .deal-description .description {
    flex: 3;
    padding-left: 0.5rem;
}

.deal-middle .more-deals {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    font-size: 0.7rem;
    font-weight: bold;
    color: #ef5800;
    align-self: center;
}

.deal-right {
    flex: 1;
    font-size: 0.8rem;
    padding: 1rem;
}

.deal-right .deal-price {
    margin: 0.5rem;
}

@keyframes highlight {
    0% {
        box-shadow: 0 0 0.9rem #ff8b33;
    }
    100% {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

.highlight {
    #animation: highlight 4s;
    box-shadow: 0 0 0.9rem #b7c4e8;
    border: 3px solid #b7c4e8;
}

.outside-search {
    font-size: 0.8rem;
    margin: 0 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #ef5800;
}

.outside-filter {
    opacity: 0.6;
}
