html,
body {
    position: relative;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    height: 100%;
    margin: 0;
}

@media (max-width: 736px) {
    html,
    body {
        font-size: 14px;
    }
}

#root {
    height: 100%;
}

a {
    color: #4a4a4a;
}

.browser-support {
    text-align: center;
}

.main {
    height: 100%;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-flow: column nowrap;
}

.navbar {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    color: white;
    min-height: 0;
    flex: 0 0 auto;
    /*box-shadow: 0px 2px 3px #00000029;*/
    padding: 0 1rem 0 1rem;
}

.navbar > .container {
    min-height: 0;
}

.navbar-items {
    width: 100%;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: center;
    justify-content: space-between;
}

.nav-left {
    display: flex;
}

.nav-left .navbar-item {
    color: #ef5800;
    font-weight: bold;
}

.nav-right {
    display: flex;
}

.nav-right .navbar-item {
    font-family: Arial;
    font-size: 0.8rem;
}

.navbar-item {
    line-height: normal;
    font-size: 1rem;
    letter-spacing: 0px;
    opacity: 1;
    display: flex !important;
    align-items: center !important;
}

.navbar-item.sliding-menu-nav-btn {
    display: none !important;
}

@media (max-width: 768px) {
    .nav-right .navbar-item {
        display: none !important;
    }

    .nav-right .navbar-item.sliding-menu-nav-btn {
        display: flex !important;
    }
}

.navbar-item.active a {
    text-decoration: underline;
    text-decoration-color: #ef5800;
    text-decoration-style: solid;
    color: #ef5800;
}

.sliding-menu-item.active a {
    text-decoration: underline;
    text-decoration-color: #ef5800;
    text-decoration-style: solid;
}

.navbar-item:hover a,
.sliding-menu-item:hover a {
    color: #ef5800;
}

.fa-bars {
    font-size: 1.3rem;
    font-weight: bold;
    color: #363636;
}

.fa-times {
    font-size: 1.2rem;
}

.sliding-menu {
    z-index: 100;
}

.sliding-menu .sliding-menu-content {
    display: flex;
    flex-direction: column;
}

.sliding-menu .sliding-menu-item {
    font-size: 1rem;
    padding: 1rem;
    font-family: Arial;
    color: #363636;
    font-weight: bold;
}

.logo-img {
    height: 2.8rem;
    max-height: none !important;
}

.dealiem-title {
    color: white;
    font-size: 1.2rem;
}

.filter-section {
    padding: 1rem;
    flex: 0 0 auto;

    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    opacity: 1;
}

.filter-item {
    margin-right: 0.5rem;
}

@media (max-width: 1200px) {
    .filter-item {
        margin-bottom: 0.5rem;
    }
}

.main-section {
    flex: 1 1 0;
    display: flex;
}

.main-section .container {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.main-section .container .columns {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    flex: 1 0 0;
}

@media (max-width: 768px) {
    .main-section {
        flex: 1 0 auto;
    }
}

.loading-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-col .fa {
    font-size: 2rem;
}

.loading-col .location-access {
    font-size: 0.8rem;
}

.white {
    color: #fff;
}

.distance-pill {
    background: #f9f9f9 0 0 no-repeat padding-box;
    border: 1px solid #5a00bb;
    border-radius: 30px;
    opacity: 1;
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
    font-weight: normal;
    vertical-align: center;
    align-self: center;
}

.fa-star {
    margin-right: 0.3rem;
    color: #ff8b33;
}

.feature-pill {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #8491b4;
    border-radius: 30px;
    opacity: 1;
    font-weight: normal;
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
    align-items: center;
}
